<template>
  <v-select
    v-bind="$attrs"
    item-text="name"
    item-value="Ticker"
    @input="$emit('input', $event)"
    outlined
  >
  <!-- TODO: perhaps can be refactored to avoid duplication -->
    <template v-slot:selection="{ item: asset }" >
      <v-row align="center">
        <v-col cols=2 sm=1>
          <v-img
            :src="logo(asset.Name)"
            max-width="30"
          >
          </v-img>
        </v-col>
        <v-col>
          {{asset.Name}}
        </v-col>
        <v-col>
          {{asset.Ticker}}
        </v-col>
      </v-row>
    </template>
    <template v-slot:item="{ item: asset }" >
      <v-row align="center">
        <v-col cols=2 sm=1>
          <v-img
            :src="logo(asset.Name)"
            max-width="30"
          >
          </v-img>
        </v-col>
        <v-col>
          {{asset.Name}}
        </v-col>
        <v-col>
          {{asset.Ticker}}
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AssetSelect',
  props: {
    filteredAssetInfo: {
      type: Object,
    },
  },
  computed: mapGetters([
    'logo',
  ]),
};
</script>
